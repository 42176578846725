import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/auth/Login';
import ResetPassword from './pages/auth/ResetPassword';
import PNF from './pages/404';
import { checkLogin, checkRole } from './auth';
import Signup from './pages/auth/Signup';
import Setting from './pages/dashboard/Setting';
import OrdersList from './pages/dashboard/OrdersList';
import ChangePassword from './pages/auth/ChangePassword';
import AccountSuspended from './pages/dashboard/AccountSuspended';
import UserProfile from './pages/dashboard/UserProfile';
import CustomerSupport from './pages/dashboard/CustomerSupport';
import OrderDetails from './pages/dashboard/OrderDetails';
import TwoStepVerification from './pages/auth/TwoStepVerification';


const Router = () => {
  return (
    <Routes>
      {/* auth-routes */}
      <Route
        exact
        path='/'
        element={!checkLogin() ? <Login /> : <Navigate to='/dashboard' replace={true} />}
      />
      <Route
        exact
        path='/signup'
        element={!checkLogin() ? <Signup /> : <Navigate to='/' />}
      />
      <Route
        exact
        path='/resetpassword'
        element={!checkLogin() ? <ResetPassword /> : <Navigate to='/' />}
      />
      <Route
        exact
        path='/changepassword'
        element={!checkLogin() ? <ChangePassword /> : <Navigate to='/' />}
      />
      <Route path="/userverification/:userToken"
        element={!checkLogin() ? <TwoStepVerification /> : <Navigate to='/' />}
      />

      {/* dashboard routes */}

      <Route
        exact
        path='/dashboard'
        element={checkLogin() ? <Dashboard /> : <Navigate to='/' />}
      />

      <Route
        exact
        path='/orderslist'
        element={checkLogin() ? <OrdersList /> : <Navigate to='/' />}
      />
      <Route
        exact
        path='/setting'
        element={checkLogin() && checkRole() ? <Setting /> : <Navigate to='/' />}
      />
      <Route path="/order/:orderId"
        element={checkLogin() ? <OrderDetails /> : <Navigate to='/' />}
      />
      <Route path="/accountsuspended"
        element={!checkLogin() ? <AccountSuspended /> : <Navigate to='/' />}
      />
      <Route path="/userprofile"
        element={checkLogin() ? <UserProfile /> : <Navigate to='/' />}
      />
      <Route path="/customersupport"
        element={<CustomerSupport />}
      />
      <Route path='*' element={<PNF />} />
    </Routes>
  );
};

export default Router;
